<template>
  <ul v-if="!newStyles" class="mb-0 mt-4 hidden list-none sm:block">
    <li v-for="(tab, index) in tabs" :key="index" class="inline-block text-center last:mr-0">
      <NuxtLink
        :to="tab.link"
        :data-test="tab.link.replaceAll('/', '-')"
        class="tabs-links__link bg-p-blue-700 hover:bg-p-yellow-500 block uppercase hover:text-black"
        :class="[
          tab.link === $route.fullPath
            ? 'bg-p-yellow-500 pointer-events-none text-black'
            : 'text-white',
        ]"
      >
        {{ tab.name }}
      </NuxtLink>
    </li>
  </ul>
  <div
    v-else
    class="flex w-full items-center justify-between border-b-[#223855]"
    :class="[isLarge ? 'mb-5 border-b-4' : 'mb-4 border-b-2', $attrs.class]"
  >
    <ul class="hidden list-none sm:block">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        class="relative inline-block text-center last:mr-0"
        :class="{
          [isLarge ? 'tab-item-active' : 'tab-item-active-small']: isActiveTab(tab),
        }"
      >
        <component
          :is="isLinkComponent(tab)"
          v-bind="makeLink(tab)"
          :data-test="'tab-' + makeDataTest(tab)"
          class="group flex capitalize text-white hover:text-white"
          :class="[
            {
              'pointer-events-none': isActiveTab(tab),
              'cursor-pointer text-opacity-60': !isActiveTab(tab),
            },
            isLarge ? 'p-3 pb-1 text-lg font-medium leading-4' : 'p-2 text-base leading-4',
          ]"
          @click="clickTab(tab)"
        >
          <Icon
            v-if="withIcon"
            :name="tab.icon"
            class="tab-icon h-4 w-4 group-hover:text-white group-hover:text-opacity-100"
            :class="{
              'text-p-yellow-600': isActiveTab(tab),
              'text-white text-opacity-60': !isActiveTab(tab),
            }"
          />
          <span :class="{ 'pl-2.5': isLarge }">{{ tab.name }}</span>
        </component>
      </li>
    </ul>
    <nuxt-link
      v-if="showReturnToOverview"
      class="hover:underline hover:decoration-white"
      :to="returnToOverviewLink"
      data-test="return-to-overview"
    >
      <span class="text-xs font-bold text-white">{{ returnToOverviewTitle }}</span>
    </nuxt-link>
  </div>
</template>

<script setup lang="ts">
import { computed, resolveComponent } from 'vue'
import { useRoute } from 'nuxt/app'
import formatString from '~/utils/formatString'

interface Tab {
  name: string
  link: string
  icon?: string
  type?: string
}

const emit = defineEmits<{
  (e: 'clickTab', tab: Tab): void
  (e: 'switchActiveTab', index: number): void
}>()
const props = withDefaults(
  defineProps<{
    tabs: Tab[]
    newStyles?: boolean
    withIcon?: boolean
    size?: 'large' | 'small'
    selectedTab?: Tab
    activeEqualName?: boolean
    selectedCurrentTab?: Tab
    withReturnToOverview?: boolean
  }>(),
  {
    tabs: () => [],
    newStyles: false,
    withIcon: true,
    size: 'large',
    selectedTab: undefined,
    activeEqualName: false,
    selectedCurrentTab: undefined,
    withReturnToOverview: false,
  },
)

const route = useRoute()

const isLarge = computed(() => {
  return props.size === 'large'
})

const returnToOverviewTitle = computed(() => {
  const tab = props.selectedCurrentTab ?? props.selectedTab
  return `Return to ${tab?.name} Overview`
})

const urlSelectedCurrentTab = computed(() => {
  const tab = props.selectedCurrentTab ?? props.selectedTab
  const url = formatString(tab?.link, props.selectedTab?.type)
  return url
})

const returnToOverviewLink = computed(() => {
  return urlSelectedCurrentTab.value
})

const showReturnToOverview = computed(() => {
  if (!props.withReturnToOverview) return false

  const url = urlSelectedCurrentTab.value
  return url !== route.fullPath
})

const isActiveTab = (tab) => {
  if (!props.selectedTab) {
    return tab.link === route.fullPath
  }

  if (props.selectedTab && props.activeEqualName) {
    return props.selectedTab.name === tab.name
  }

  return route.fullPath.includes(tab.type)
}
const makeLink = (tab) => {
  if (!tab.link) return {}
  if (!props.selectedTab) return { to: tab.link }

  const url = formatString(tab.link, props.selectedTab.type)
  return { to: url }
}
const makeDataTest = (tab) => {
  if (!tab.link) return tab.name.toLowerCase().replaceAll(' ', '-')
  if (!props.selectedTab) return tab.link.replaceAll('/', '-')

  const dataTest = formatString(tab.link, 'child')
  return dataTest.replaceAll('/', '-')
}
const isLinkComponent = (tab) => {
  return tab.link ? resolveComponent('NuxtLink') : 'div'
}
const clickTab = (tab) => {
  if (isActiveTab(tab)) return
  emit('switchActiveTab', tab)
}
</script>

<style>
.tabs-links__link {
  font-size: 10px;
  letter-spacing: 1px;
  padding: 15px 31px;
  box-shadow: inset 0 4px 4px rgb(15 15 15 / 25%);
}

.tab-icon svg {
  @apply h-4 w-4;
}

.tab-item-active::before {
  content: '';

  @apply bg-p-yellow-600 absolute bottom-[-7px] left-0 h-1 w-full;
}

.tab-item-active-small::before {
  content: '';

  @apply bg-p-yellow-600 absolute bottom-[-2px] left-0 h-[2px] w-full;
}
</style>
